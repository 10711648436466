<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol col="6" class="text-left"><h4>Top-up Details</h4></CCol>
            <CCol col="6" class="text-right">
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>

          <CRow>
            <CCol col="12" class="text-left">
              <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
            </CCol>
          </CRow>

          <div class="text-center">
            <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
          </div>


          <b-card-group>
            <b-card  header="" header-tag="header" footer-tag="footer" title="">
              <b-row>
                <b-col sm="10">
                  <div class="form-group">
                    <label for="input-small" >Top-up Codes</label>
                    <multiselect :taggable="true" @tag="addTag" v-model="searches" :options="optSearch" :multiple="true" :showNoOptions="false" placeholder="Input Top-up Codes" label="name" track-by="code" :regex="/^[A-Za-z0-9*]/">
                      <template slot="selection2" slot-scope="{ values, search, isOpen }"><pre class="language-json"><code>{{ value  }}</code></pre></template>
                    </multiselect>
                    <label style="color:grey; font-size : 13px;"> press <strong>ENTER</strong> to input new top-up code</label>
                  </div>
                </b-col>
                <b-col sm="2">
                  <div class="form-group">
                    <label for="button" >&nbsp;&nbsp;&nbsp;</label>
                    <b-button variant="primary" v-on:click="searchTopupCode()" >Get Top-up Data</b-button>
                  </div>
                </b-col>
              </b-row>
              <b-alert
                  :show="dismissCountDown"
                  dismissible
                  variant="danger"
                  @dismissed="dismissCountDown=0"
                  @dismiss-count-down="countDownChanged"
              >
                <p v-for="errors in error_topup">
                  Data Top-up Code <strong>{{ errors.error_code}}</strong> {{ errors.error_message}}
                </p>
                <b-progress
                    variant="danger"
                    :max="dismissSecs"
                    :value="dismissCountDown"
                    height="4px"
                ></b-progress>
              </b-alert>
            </b-card>
          </b-card-group><br/>

          <b-row v-if="pbar">
            <b-col sm="12">
              <b-progress :max="max" height="2rem" show-progress animated>
                <b-progress-bar :value="value" variant="success" :label="`${((value / max) * 100).toFixed(2)}%`"></b-progress-bar>
              </b-progress>
            </b-col>
          </b-row><br/>

          <div class="table-responsive table">
            <b-table striped hover :items="items" :fields="fields" sort-by="transactionDate" :sort-desc="true" :current-page="currentPage" :per-page="perPage" :filter="filter">

              <template v-slot:cell(topUpCode)="{ item }">
                {{ item.topUpCode | filterOnly }}
              </template>

              <template v-slot:cell(amount)="{ item }">
                {{ item.amount | setValDefault(item.currency) }}
              </template>

              <template v-slot:cell(transactionDate)="{ item }">
                {{ item.transactionDate | dateSubstr }}
              </template>


            </b-table>
          </div><br/>

          <div>
            <b-row>
              <b-col sm="3">
                <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
              </b-col>
              <b-col sm="9">
                <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
                </b-form-select>
              </b-col>
            </b-row>
          </div>
        </CCardBody>

      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import AuthService from '@/api/AuthService.js';
import Multiselect from 'vue-multiselect';
import VueTimepicker from 'vue2-timepicker';
import JsonExcel from "vue-json-excel";

export default {
  name: "topupDetails",
  components: {
    Multiselect,
    VueTimepicker,
    'downloadExcel' : JsonExcel
  },
  data() {
    var date = new Date();
    return {
      isReadOnly:false,
      isDisabled:false,
      showDismissibleAlert:false,
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      pbar:false,
      value: 0,
      dismissSecs: 10,
      dismissCountDown: 0,
      max:0,
      idrPaynow:["WALLET_IDR","WALLET_IDR_PROXY"],
      phpPaynow:["WALLET_PHP"],
      dbsMca:["WALLET_DBS_MCA","MANUAL_DBS_MCA"],
      proxyTypeList:["NRIC","MOBILE","UEN"],
      spinner:false,
      json_fields:'',
      json_data:'',
      time:'',
      timeRange:'',
      defOptSts:'',
      defOptCurr:'',
      cbstatus:false,
      cbReSts:false,
      attrsFrom: {
        description : ""
      },
      attrsTo: {
        description : ""
      },
      attrMSelect:{
        placeholder : ""
      },
      fromDate: new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substr(0, 10),
      toDate: new Date().toISOString().substr(0, 10),
      defoptType:null,
      optType:[
        { value: 'P2P', text: 'P2P'},
        { value: null, text: 'ALL'}
      ],
      optStatus: [
        { name: 'COMPLETED'},
        { name: 'CANCELED'},
        { name: 'REFUNDED'}
      ],
      baseOptStatus:[],
      optCurrency: [
        { name: 'SGD' },
        { name: 'IDR' },
        { name: 'USD' }
      ],
      baseOptType:[],
      searches:[],
      optSearch:[],
      items:[],
      rows:[],
      type:[],
      status: [],
      currency:[],
      amountFrom:null,
      amountTo:null,
      search:null,
      fields: [
        {
          name : 'transactionId',
          key: 'transactionId',
          label : 'Transaction ID',
          sortable: true
        },
        {
          name : 'transactionDate',
          key: 'transactionDate',
          label : 'Transaction Date',
          sortable: true
        },
        {
          name : 'beneficiaryName',
          key: 'beneficiaryName',
          label : 'Beneficiary',
          sortable: true
        },
        {
          name : 'beneficiaryPhone',
          key: 'beneficiaryPhone',
          label : 'Beneficiary Phone',
          sortable: true
        },
        {
          name : 'currency',
          key: 'currency',
          label : 'Currency',
          sortable: true
        },
        {
          name : 'amount',
          key: 'amount',
          label : 'Amount($)',
          sortable: true
        },
        {
          name : 'status',
          key: 'status',
          label : 'Status',
          sortable: true
        },
        {
          name : 'topUpCode',
          key: 'topUpCode',
          label : 'Top-up Code',
          sortable: true
        }
      ],
      currentPage: 1,
      perPage: 10,
      filter: null,
      data:[],
      columns:[],
      error_topup:[],
      formPasswd:false,
      filename:"user-transaction.xlsx",
      password:"",
      seen:false,
      msg :'',
      color:'',
      userStatus: null,
      seenCbOtUsr:false,
      initialLoad:true,
    };
  },
  filters: {
    upper(value){
      if(!value) return ''
      value = value.toString()
      return value.toUpperCase()
    },
    lower(value){
      if(!value) return ''
      value = value.toString()
      return value.toLowerCase()
    },
    dateSubstr(value){
      if(!value) return ''
      value = value.toString()
      value = value.split(".")
      return value[0].replace("T"," ")
    },
    IdSubstr(value){
      if(!value) return ''
      value = value.toString()
      return value.substring(0,5) + '...'
    },
    changeLabel(value,dbsTT,payM){
      if(!value) return ''
      if(dbsTT==='BUYNOW') return 'BUYNOW_SGQR'
      if(payM==='WALLET_IDR') return 'IDR_PAYNOW'
      else return value
    },
    filterOnly(value){
      if(!value) return ''
      if(['EW', 'SS'].includes(value.substr(0,2))) return value;
      else return ''
    },
    setValDefault(value, fix_currency='SGD'){
      if(!value) return 0
      else return parseFloat(value).toLocaleString('en-Us', {style:"currency", currency:fix_currency})
    }
  },

  created () {

    this.getConfigList();
    this.setColumns();
  },
  methods: {

    searchTopupCode(){
      this.retrieveTransact();
    },

    onModifyClick(e) {
      if(e){
        this.attrMSelect.placeholder="ALL";
        this.defOptSts='';
        this.optStatus=[];
        this.status =[];
      } else {
        this.attrMSelect.placeholder="Pick Some";
        this.optStatus = this.baseOptStatus;
      }
    },

    _onModifyClick(e) {
      if(e){
        this.userStatus = "SYSTEM_GENERATED";
      } else {
        this.userStatus = null;
      }

    },

    sortByProperty(property){
      return function(a,b){
        if(a[property] > b[property])
          return 1;
        else if(a[property] < b[property])
          return -1;
        return 0;
      }
    },

    getConfigList: function() {
      AuthService.getConfigList().then(resp => {      //console.log(resp.transactionType);

        var currSrc=[];
        var arrcurrency = resp.currency;
        for (const item of arrcurrency){ currSrc.push({ name : item}); }
        this.optCurrency = currSrc;

        var typeSrc=[];
        var arrTypes = resp.transactionType;
        typeSrc.push({ value: null  , text: 'ALL' });
        typeSrc.push({ value: 'WALLET_COIN_PHP'  , text: 'COIN_PHP' });
        typeSrc.push({ value: 'DBS_PAYNOW_DASH'  , text: 'DBS_PAYNOW_DASH' });
        typeSrc.push({ value: 'DBS_PAYNOW_GRAB'  , text: 'DBS_PAYNOW_GRAB' });
        typeSrc.push({ value: 'DBS_MCA'  , text: 'DBS_MCA' });
        for (const item2 of arrTypes) {
          if(item2!=="ADMIN") { typeSrc.push({ value: item2 , text: item2 }); }
        }
        typeSrc.push({ value:"WALLET_GRAB_PHP", text:"GRAB_PHP" });
        typeSrc.push({ value: 'IDR_PAYNOW'  , text: 'IDR_PAYNOW' });
        typeSrc.push({ value: 'PHP_PAYNOW'  , text: 'PHP_PAYNOW' });
        //typeSrc.push({ value: 'DBS_PAYNOW_PROXY'  , text: 'DBS_PAYNOW_PROXY' });
        typeSrc.push({ value: 'REDEEM'  , text: 'REDEEM' });
        typeSrc.push({ value: 'SGQR_BUYNOW'  , text: 'SGQR_BUYNOW' });


        typeSrc.sort(this.sortByProperty("text"));
        this.optType = typeSrc;
        this.baseOptType = typeSrc;

        var mvStsSrc=[];
        var arrMvSts = resp.movementStatus;
        for (const item3 of arrMvSts){ mvStsSrc.push({ name : item3 }); }
        this.optStatus = mvStsSrc;
        this.baseOptStatus = mvStsSrc;

      }, error => {
        console.log(error);
      });
    },

    getRequestParams() {
      let params = {};
      let search = []
      for (var key in this.searches) {
         search[key] = this.searches[key]['name'];
      }
      params["refNos"] =search;

      return params;
    },

    dateSubstr(value){
      if(!value) return ''
      value = value.toString()
      value = value.split(".")
      return value[0].replace("T"," ")
    },

    setColumns(){
      this.columns = [];
      var fields = this.fields; let row = {}; var cols = [];
      for (var key in fields) {
        if(fields[key].key !== "index"){
          row['label'] = fields[key].label;
          row['field'] = fields[key].key;
          var temp = JSON.stringify(row); cols.push(temp);
        }
      }

      for (var key2 in cols) {
        var row2 = JSON.parse(cols[key2]); this.columns.push(row2);
      }
    },

    addTag (newTag) {
      // if (newTag.)
      let regex = /^[a-zA-Z0-9-_,.@#* \b]+$/
      if (!regex.test(newTag)) {
        alert("some of the tag has disallowed character")
      } else {
        const tag = {
          name: newTag,
          code: newTag
        }
        this.searches.push(tag)
      }
    },

    async retrieveTransact(){
      if(!this.pbar){
        this.pbar = true;
        this.isReadOnly = true;
        this.isDisabled = true;
        this.max = 0; this.value = 0;
        this.filter = null; this.rows=[]; this.items=[];
        let params   = this.getRequestParams();
        console.log(params);
        await AuthService.getTopUpDetails(params).then((resp) => {
          this.error_topup = [];
          for (let key in resp.payload) {
            let temp = resp.payload[key];
            console.log(temp);
            if (typeof temp === 'object') {
              AuthService.getUsrDtlByPhone(temp.beneficiaryPhone).then(resp_user => {
                let displayName = resp_user.firstName + " " + resp_user.lastName;
                temp.beneficiaryName = displayName.trim();
                temp.topUpCode = temp.topUpCode === "" ? key : temp.topUpCode;
                let row = {
                  "transactionId": temp.transactionId,
                  "transactionDate": new Date(temp.transactionDate),
                  "beneficiary": displayName.trim(),
                  "beneficiaryPhone": temp.beneficiaryPhone,
                  "currency": temp.currency,
                  "amount": temp.amount,
                  "movementType": temp.movementType,
                  "status": temp.status,
                  "topUpCode": temp.topUpCode,
                  'beneficiaryName': displayName.trim()
                };
                this.rows.push(row);
                this.items.push(temp);
              }, error => {
                temp.beneficiary = temp.beneficiary ?? '-';
                let row = {
                  "transactionId": temp.transactionId,
                  "transactionDate": new Date(temp.transactionDate),
                  "beneficiary": temp.beneficiary,
                  "beneficiaryPhone": temp.beneficiaryPhone,
                  "currency": temp.currency,
                  "amount": temp.amount,
                  "status": temp.status,
                  "topUpCode": temp.topUpCode
                };
                this.rows.push(row);
                this.items.push(temp);
              })
            } else {
              let msg_error = (resp.payload[key] === 'NOT-FOUND') ? 'is not available' : resp.payload[key]
              let row_error = {'error_code': key, 'error_message': msg_error };
              this.error_topup.push(row_error);
            }
          }

          if (this.error_topup.length > 0) {
            this.dismissCountDown = this.dismissSecs
          }
          this.data  = this.rows;
          this.pbar  = false;
        }).catch((e) => { console.log(e); });
      } else {
        this.msg   = 'Action Ignored, Please waiting until the previous process done !';
        this.color = 'warning'; this.seen  = true;
        setTimeout( () =>  this.seen=false , 5000);
      }
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    mounted() {
      //this.retrieveTransact();
    },

  },

};

</script>

<style>
.table{overflow-y:scroll; }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-timepicker/dist/VueTimepicker.css"></style>
